<template>
  <Cascader :data="cityList" v-model="cityCode" filterable @on-change="selectCity"></Cascader>
</template>

<style>
</style>

<script>
// import leftPad from 'leftpad'
import cities from './../../assets/cities'

export default {
  props: ['city'],
  data () {
    return {
      provinces: [],
      municipalities: [],
      counties: [],
      cityList: [],
      provinceCode: '00',
      municipalityCode: '00',
      countyCode: '00',
      cityCode: []
    }
  },
  watch: {
    'city': function (newVal) {
      if (newVal && newVal.toString().length === 6) {
        let cities = []
        cities.push(newVal.substr(0, 2) + '0000')
        cities.push(newVal.substr(0, 4) + '00')
        cities.push(newVal)
        this.cityCode = cities
      }
    }
  },
  mounted () {
    // this.provinces = cities.filter((p, index, source) => {
    //   return p.code.substr(2, 4) === '0000'
    // })
    this.initCityList()
  },
  methods: {
    selectCity (value, selectedData) {
      this.$emit('selectCity', value[2], selectedData)
    },
    initCityList () {     // 如果cities.js更新，通过此函数更新json
      this.provinces = cities.filter((p) => {
        return p.code.substr(2, 4) === '0000'
      })
      this.municipalities = cities.filter((p) => {
        return p.code.substr(4, 2) === '00' && p.code.substr(2, 4) !== '0000'
      })
      this.counties = cities.filter((p) => {
        return p.code.substr(4, 2) !== '00' && p.code.substr(2, 4) !== '0000'
      })
      this.provinces.forEach((item) => {
        let prefix = item.code.substr(0, 2)
        let province = {
          value: item.code,
          label: item.city,
          children: []
        }
        this.municipalities.forEach((item1) => {
          let prefix2 = item1.code.substr(2, 2)
          if (item1.code.substr(0, 2) === prefix) {
            let municipality = {
              value: item1.code,
              label: item1.city,
              children: []
            }
            this.counties.forEach((item2) => {
              if (item2.code.substr(0, 2) === prefix && item2.code.substr(2, 2) === prefix2 && item2.city !== '市辖区') {
                let county = {
                  value: item2.code,
                  label: item2.city
                }
                municipality.children.push(county)
              }
            })
            province.children.push(municipality)
          }
        })
        this.cityList.push(province)
      })
      // console.log(JSON.stringify(this.cityList))
    }
  }
}
</script>
